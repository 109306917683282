<template>
  <div class="badge">
    <svg class="wordplay" version="1.1" x="0px" y="0px" width="3in" height="3in" viewBox="0 0 125.58 125.59">
      <path :fill="'black'" d="M33.91,85.51l4.08-2.88v-21.46h-16.33v64.42h50.61v-14.97h-34.28v-9.94l-4.08,2.81v-17.98ZM33.91,22.1v7.7c.79-.31,1.47-.71,2.04-1.2,1.04-.89,1.74-2.11,2.11-3.64l-4.14-2.86ZM38.05,24.95l13.93,9.6c2.18-3.4,3.27-7.55,3.27-12.45,0-6.94-2.13-12.36-6.39-16.26C44.61,1.95,38.65,0,30.98,0H0v71.94h16.33v-27.73h14.65c2.77,0,5.32-.26,7.66-.79l-4.73-3.34v-10.29c-1.43.57-3.23.86-5.38.86h-12.19V13.66h12.19c3.34,0,5.81.7,7.42,2.11,1.6,1.41,2.41,3.55,2.41,6.44,0,1.01-.1,1.92-.3,2.75M66.04,62.79l-5.58,3.95v-7.89l5.58,3.94ZM66.68,75.76l4.82,1.81,21.44-14.79-40.96-28.23c-.88,1.38-1.93,2.63-3.17,3.76-2.72,2.5-6.12,4.2-10.18,5.11l7.64,5.4v27.94l-8.3,5.87v18.06l18.77-12.95-4.91-1.78,14.83-10.2ZM110.41,93.33l3.59-48.5-15.56,10.68-2.01,31.44-24.93-9.38-14.74,10.16,39.95,14.48,15.18,23.37,13.7-8.89-15.18-23.37Z" />
    </svg>
    <div class="badge-body">
      <svg class="playbutton" version="1.1" x="0px" y="0px" width="3.25in" height="1in" viewBox="0 0 72 72">
        <path fill="#cf1f25" d="M35.64,0C15.96,0,0,15.96,0,35.64s15.96,35.64,35.64,35.64,35.64-15.96,35.64-35.64S55.32,0,35.64,0ZM30.89,47.45v-23.63l15.95,11.81-15.95,11.81Z"/>
        <path fill="#cf1f25" d="M93,31.35c-2.36,0-4.29,1.91-4.29,4.29s1.93,4.29,4.29,4.29,4.29-1.93,4.29-4.29-1.91-4.29-4.29-4.29Z"/>
        <path fill="#cf1f25" d="M119.02,31.35c-2.36,0-4.29,1.91-4.29,4.29s1.93,4.29,4.29,4.29,4.29-1.93,4.29-4.29-1.91-4.29-4.29-4.29Z"/>
        <path fill="#cf1f25" d="M171.06,31.35c-2.36,0-4.29,1.91-4.29,4.29s1.93,4.29,4.29,4.29,4.29-1.93,4.29-4.29-1.91-4.29-4.29-4.29Z"/>
        <path fill="#cf1f25" d="M145.04,31.35c-2.36,0-4.29,1.91-4.29,4.29s1.93,4.29,4.29,4.29,4.29-1.93,4.29-4.29-1.91-4.29-4.29-4.29Z"/>
        <path fill="#cf1f25" d="M223.1,31.35c-2.36,0-4.29,1.91-4.29,4.29s1.93,4.29,4.29,4.29,4.29-1.93,4.29-4.29-1.93-4.29-4.29-4.29Z"/>
        <path fill="#cf1f25" d="M197.08,31.35c-2.36,0-4.29,1.91-4.29,4.29s1.93,4.29,4.29,4.29,4.29-1.93,4.29-4.29-1.91-4.29-4.29-4.29Z"/>
      </svg>
       <h1>{{ registration.profile.badgeNameFull }}</h1>
       <h2 v-if="partialType" class="partial-type">{{ partialType }}</h2>
    </div>
    <logo color="#000" />

  </div>
</template>

<script>
import Logo from './Logo.vue';
import { find as _find, some as _some } from 'lodash';

export default {
  components: { Logo },
  name: 'Badge',
  props: {
    registration: Object
  },
  computed: {
    partialType() {
      // If attendee, return false
      if( _some(this.registration.tags, { slug: 'attendee' }) ) {
        return false;
      }
      
      const partialTag = _find(this.registration.tags, (o) => {
        return ['dealer', 'parties', 'monday', 'tuesday', 'wednesday'].includes(o.slug);
      }) || false;
      
      return partialTag === false ? false : partialTag.name;
    }
  }
}
</script>

<style scoped>
.badge {
  width: 3.25in;
  height: 4.75in;
  margin: 0.125in;
}
.snr-bg {
  opacity: 0.3;
  width: 3.25in;
  height: 4.75in;
  position: absolute;
  top: 0;
  left: 0;
}
.wordplay {
  position: absolute;
  bottom: -0.125in;
  left: -0.125in;
  opacity: 0.04;
}
.logo {
  position: absolute;
  bottom: 0;
  right: 0;
}
.badge-body {
  position: absolute;
  top: 0.3in;;
  left: 0;
}
h1 {
  font-size: 38pt;
  line-height: 40pt;
  color: black;
  /* word-spacing: 3.25in; */
  width: 3.25in;
}
h2.partial-type {
  position: relative;
  font-size: 18pt;
  text-transform: uppercase;
  font-family: 'Alegraya Sans Bold';
  color: #cf2127;
}
</style>